.react-dataTable {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      min-height: 38px;
      height: auto !important;

      .rdt_TableCol {
        .rdt_TableCol_Sortable {
          div {
            &:first-child {
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        min-height: 53px;
        height: auto !important;

        div {
          &:first-child {
            white-space: normal;
          }
        }

        .table-action-items {
          > a:not(:last-child) {
            margin-right: 8px;
          }

          > a:is(:last-child) {
            margin-right: 16px;
          }

          .table-action-item-danger {
            svg {
              stroke: #ea5455;
            }
          }
        }
      }
    }
  }
}
